<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark fixed-top"
      id="mainNavbar"
      style="padding: 0"
    >
      <div class="container">
        <a href="/" class="navbar-brand"
          ><img
            class="img-fluid"
            src="../assets/adastralogo.png"
            alt="evren makina logo"
        /></a>
        <button
          type="button"
          class="navbar-toggler border border-0"
          data-bs-toggle="offcanvas"
          data-bs-target="#myNavbar"
          aria-controls="myNavbar"
          aria-label="menuyu acma kapama butonu"
          aria-expanded="false"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="myNavbar"
          aria-labelledby="myLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="myLabel">AD ASTRA</h5>
            <button
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Kapat"
            ></button>
          </div>

          <div class="offcanvas-body overflow-hidden">
            <ul
              class="
                navbar-nav
                justify-content-end
                flex-grow-1
                text-uppercase
                ms-auto
                py-lg-2
                px-md-5
                cf
              "
            >
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                :class="teknikClass"
              >
                <router-link to="/qr" class="nav-link" active-class="active"
                  >QR Okuma</router-link
                >
              </li>
              <li
                class="nav-item"
                data-bs-dismiss="offcanvas"
                :class="dashClass"
              >
                <router-link
                  to="/dashboard"
                  class="nav-link"
                  active-class="active"
                  >Dashboard</router-link
                >
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <a href="/" class="nav-link">ANASAYFA</a>
              </li>
              <li class="nav-item" v-if="!menu" data-bs-dismiss="offcanvas">
                <router-link to="/about" class="nav-link"
                  >HAKKIMIZDA</router-link
                >
              </li>
              <li class="nav-item" v-if="!menu" data-bs-dismiss="offcanvas">
                <router-link to="/projeler" class="nav-link"
                  >PROJELER</router-link
                >
              </li>
              <!-- <li class="nav-item" v-if="!menu" data-bs-dismiss="offcanvas">
                <router-link to="/hizmetler" class="nav-link"
                  >HİZMETLER</router-link
                >
              </li> -->
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link to="/magaza" class="nav-link">MAGAZA</router-link>
              </li>
              <li class="nav-item" v-if="!menu" data-bs-dismiss="offcanvas">
                <router-link to="/iletisim" class="nav-link"
                  >İLETİŞİM</router-link
                >
              </li>
              <li
                class="nav-item position-relative"
                v-if="menu"
                data-bs-dismiss="offcanvas"
              >
                <router-link to="/sepet" class="nav-link"
                  >SEPET
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-cart"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                    /></svg>
                ></router-link>
               <span
                  v-if="userId == '' && shopCart.length > 0"
                  class="
                    position-absolute
                    top-0
                    start-100
                    badge
                    rounded-pill
                    bg-danger
                  "
                  >{{ shopCart.length }}
                  <span class="visually-hidden">unread messages</span></span
                >
                 <span
                  v-if="userId != '' && shopCount >0"
                  class="
                    position-absolute
                    top-0
                    start-100
                    badge
                    rounded-pill
                    bg-danger
                  "
                  >{{ shopCount }}
                  <span class="visually-hidden">unread messages</span></span>
              </li>
              <li class="nav-item" v-if="menu" data-bs-dismiss="offcanvas">
                <router-link to="/hesap" class="nav-link">HESAP</router-link>
              </li>
              <li class="nav-item" :class="logoutClass">
                <a class="nav-link" @click.prevent="logouta">ÇIKIŞ</a>
              </li>
              <li
                class="nav-item"
                :class="logoutClass1"
                data-bs-dismiss="offcanvas"
              >
                <router-link to="/login" class="nav-link"
                  >GİRİŞ YAP</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logout", "changemenu", "changemenuf", "fetchCard"]),
    logouta() {
      this.logout();
    },
  },
  computed: {
    ...mapState(["menu", "shopCart", "shopCount", "userId"]),
    logoutClass() {
      return {
        lihide: !this.$store.getters.showclass,
      };
    },
    logoutClass1() {
      return {
        lihide1: this.$store.getters.showclass,
      };
    },
    teknikClass() {
      return {
        tekni: !this.$store.getters.isTeknik,
      };
    },
    dashClass() {
      return {
        dash: !this.$store.getters.isAuthenticated,
      };
    },
  },
};
</script>

<style  scoped>
#mainNavbar .navbar-nav .nav-item .nav-link {
  font-size: 1rem;
  color: #fff;
  letter-spacing: 0.06em;
}
.nav-link:hover {
  border-bottom: solid 1px #fff;
}

.navbar-brand img {
  height: 150px;
}
@media (max-width: 991.98px) {
  #mainNavbar .navbar-nav .nav-item .nav-link {
    color: #212529;
  }
  #mainNavbar {
    background: #212529;
  }

  #mainNavbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}

@media (min-width: 992px) {
  #mainNavbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: none;
    background-color: transparent;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
    -webkit-transition: padding-top 0.3s ease-in-out,
      padding-bottom 0.3s ease-in-out;
    -moz-transition: padding-top 0.3s ease-in-out,
      padding-bottom 0.3s ease-in-out;
    -ms-transition: padding-top 0.3s ease-in-out,
      padding-bottom 0.3s ease-in-out;
    -o-transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #mainNavbar .navbar-brand img {
    transition: font-size 0.3s ease-in-out;
    -webkit-transition: font-size 0.3s ease-in-out;
    -moz-transition: font-size 0.3s ease-in-out;
    -ms-transition: font-size 0.3s ease-in-out;
    -o-transition: font-size 0.3s ease-in-out;
  }

  #mainNavbar .navbar-nav .nav-item {
    margin-right: 1rem;
  }

  #mainNavbar .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }

  #mainNavbar.navbar-mobile .navbar-brand {
    width: 15px;
    height: 15px;
  }

  #mainNavbar.navbar-mobile {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #212529;
  }
}

.lihide {
  display: none;
}
.lihide1 {
  display: none;
}
.tekni {
  display: none;
}
.dash {
  display: none;
}
@media screen and (max-width: 1422px) {
  .navbar-brand img {
    height: 100px;
  }
}
@media screen and (max-width: 1155px) {
  .navbar-brand img {
    height: 70px;
  }
}
</style>