<script>
//import Recentproject from "@/components/Recentproject.vue";
import { mapActions } from "vuex";
import "@/animation";
export default {
  name: "HomeView",
  components: {
   // Recentproject,
  },
  data() {
    return {
      mainpage: {},
      projects: {},
    };
  },
  methods: {
    ...mapActions(["fetchMain", "fetchProjects", "changemenuf"]),
  },
  async mounted() {
    this.mainpage = await this.fetchMain();

    this.projects = await this.fetchProjects();
    this.changemenuf();
  },
};
</script>

<template>
  <div>
    <!-- <div class="wrapper1">
      <div class="wrapper2">
        <div class="bg-image"></div>
        
        <img
          src="../assets/homebackground.png"
          class="img-fluid photo"
          alt="..."
        />
        <div class="content">
          <h1 class="title" v-if="mainpage.data">
            {{ mainpage.data[0].value }}
          </h1>
          <p class="description" v-if="mainpage.data">
            {{ mainpage.data[1].value }}
          </p>
          <a href="/iletisim">BİZE ULAŞIN</a>
        </div>
      </div>
    </div> -->

    <div class="deneme"> <canvas id="C"></canvas>

    <div class="position-absolute top-50 start-50 translate-middle">
      <div class="content">
        <h1 class="title" v-if="mainpage.data">
          {{ mainpage.data[0].value }}
        </h1>
        <p class="description" v-if="mainpage.data">
          {{ mainpage.data[1].value }}
        </p>
        <!-- <img class="img-fluid" src="../assets/deneme.png" alt=""> -->
        <a href="/iletisim">BİZE ULAŞIN</a>
      </div>
    </div></div>
   

    <!-- <div class="content">
          <h1 class="title" >
            ADASTRA'ya HOŞGELDİN
          </h1>
          <p class="description">
            UZAY VE ROBOT ŞİRKETİ
          </p>
          <a href="/iletisim">BİZE ULAŞIN</a>
        </div>
      </div>
    </div>
    <Recentproject/>
    <Recentproject/> -->
    <!-- <div class="aaa" v-for="project in projects.data" :key="project.id">
      <Recentproject v-if="project.status" :project="project" />
    </div> -->
  </div>
</template>

<style scoped>
.deneme {
  overflow: hidden;
  position: relative;
}
.wrapper1 {
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #000;
}

.wrapper2 {
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;

  background-position: center;
  background-repeat: no-repeat;
}

.photo {
  position: absolute;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: aliceblue;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 99;
}
figure {
  display: block;
}
.title {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 28px;
}
.description {
  font-size: 17px;
  text-align: center;
 
  font-weight: bold;
}
a {
  padding: 15px 40px;

  color: #fff;

  font-size: 20px;
  font-weight: medium;
  outline: none;
  position: relative;
  border: none;
  backdrop-filter: blur(4px);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.6);
}
a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    50deg,
    transparent 50%,
    rgba(255, 255, 255, 0.1) 60%,
    rgba(255, 255, 255, 1) 70%,
    transparent 71%
  );
  background-size: 200% 100%;
  background-position: 200% 0;
  transition: 0.6s;
}
a:hover:after {
  background-position: -20% 0;
  cursor: pointer;
}
@media screen and (max-width: 1210px) {
  .description {
    max-width: 900px;
  }
  .title {
    font-size: 48px;
  }
}
@media screen and (max-width: 480px) {
  .description {
    max-width: 460px;
  }
  .title {
    font-size: 26px;
  }
  a {
    padding: 10px 20px;

    color: #fff;

    font-size: 14px;
  }
  
}
</style>