import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);




const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    //component: AboutView
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DashboardEvren.vue"),
    beforeEnter(to, from, next) {
      if ($cookies.get("tokenAdastra")) {
        let decoded = jwt_decode($cookies.get("tokenAdastra"));

        Object.keys(decoded).forEach(function (key) {
          if (key == "http://schemas.microsoft.com/ws/2008/06/identity/claims/role") {
            const role = decoded[key]

            if (role.includes("ROLE_ADMIN")) {
              next()
            } else {
              next("/iletisim")
            }
          }
        });


      } else {
    
        next("/")
      }


    }
  },
  {
    path: "/projeler",
    name: "projeler",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProjectView.vue"),
  },
  {
    path: "/projeler/:projectId",
    name: "detay",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProjectDetail.vue"),
  },
  {
    path: "/hizmetler",
    name: "hizmetler",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ServicesView.vue"),
  },
  {
    path: "/iletisim",
    name: "iletisim",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginRegister.vue"),
  },
  {
    path: '/magaza',
    name: 'magaza',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Shop.vue"),
  },
  {
    path: '/sepet',
    name: 'sepet',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
   
  },
  {
    path: "/magaza/:productId",
    name: "productdetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductDetail2.vue"),
  },

  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/notFound.vue")
  },
  {
    path: '/hesap',
    name: 'hesap',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Account.vue"),
    /*beforeEnter(to, from, next) {
      if (localStorage.getItem("tokenAdastra")) {
        next()
      } else {
        console.log("asjdıasjdbıajshdb");
        next("/login")
      }


    }*/
  },
  {
    path: '/destek/:productId',
    name: 'qr',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QrCode.vue"),
  },
  {
    path: '/qr',
    name: 'qrReader',
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QrReader.vue"),
      beforeEnter(to, from, next) {
        if ($cookies.get("tokenAdastra")) {
          let decoded = jwt_decode($cookies.get("tokenAdastra"));
  
          Object.keys(decoded).forEach(function (key) {
            if (key == "http://schemas.microsoft.com/ws/2008/06/identity/claims/role") {
              const role = decoded[key]
  
              if (role.includes("ROLE_ADMIN") || role.includes("ROLE_TEKNIK")) {
                next()
              } else {
                next("/")
              }
            }
          });
  
  
        } else {
          console.log("asjdıasjdbıajshdb");
          next("/")
        }
  
  
      }
  },
];





const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
