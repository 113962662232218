<template>
  <div class="ss">
    <div class="pulse">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <div class="rocket"><img src="../assets/rocket.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.pulse {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #1763a6;
  border-radius: 50%;
}
.pulse span {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1763a6;
  border-radius: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
  animation: animite 2.5s linear infinite;
  animation-delay: calc(-0.5s * var(--i));
}
@keyframes animite {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  90% {
    transform: scale(3);
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.rocket {
  position: relative;
  top: 50px;
  text-align: center;
  animation: rocket 0.1s ease infinite;
}
@keyframes rocket {
  0%,
  100% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
}
.rocket::before {
  content: "";
  position: absolute;
  top: 98px;
  left: 70%;
  transform: translateX(-50px);
  width: 20px;
  height: 250px;
  background: linear-gradient(#ffc107, transparent);
}
.rocket::after {
  content: "";
  position: absolute;
  bottom: -250px;
  left: 50%;
  transform: translateX(-50px);
  width: 20px;
  height: 250px;
  background: linear-gradient(#ffc107, transparent);
  filter: blur(20px);
}
@media (max-width: 991.98px) {
  .pulse {
    width: 100px;
    height: 100px;
  }
  .rocket{
    top: 0;
    
  }
  .rocket::before{
    left: 90%;
  }
}
</style>