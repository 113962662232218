<script>
export default {
  name: "infotwo",
  props:{
      item:Object
    }
};
</script>
<template>
  <div class="wrapper">
   <div class="content">
       <p class="title">{{item.key}}</p>
        <!-- <p class="title">Vizyon</p> -->
        
         <p>{{item.value}}</p>
        <!-- <p>Daha iyi bir gelecek için teknolojiler geliştiriyoruz. </p> -->
      </div>
    <div class="photo">
      <figure>
        <img src="../assets/hak.png" alt="A304" style="width: 100%" />
      </figure>
      
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  position: relative;
}

.photo img {
  width: 100%;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 15px;
  padding: 0 70px 0 50px;
  max-width: 50%;
  min-width: 50%;
  word-break: break-all;
}

.title {
  display: block;
  font-size: 32px;
  font-weight: bold;
  border-bottom: solid 3px #fff;
  color: white;
}



p {
  font-size: 18px;
  font-weight: normal;
  color: #d1d1d1;
}

.photo .btn {
  display: block;
  padding: 18px 0;
  border: solid 3px #fff;
  width: 160px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

@media screen and (max-width: 1250px) {
  .content {
    padding: 0 10px 0 50px;
    margin: 10px 0 20px 0;
  }

  
}

@media screen and (max-width: 1150px) {
  .content {
    padding: 0 10px 0 20px;
  }
}

@media screen and (max-width: 950px) {
  .wrapper {
    flex-direction: column-reverse;
  }
  .content {
    max-width: 950px;
  }
}
@media screen and (max-width: 600px) {
  .photo .btn {
    display: block;
    padding: 10px 0;

    width: 100px;
  }
  .content {
    row-gap: 10px;
  }
}
</style>