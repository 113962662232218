<script>
import Info from "@/components/Info.vue";
import Infotwo from "@/components/Infotwo.vue";
import { mapActions } from "vuex";
export default {
  name: "About",
  data() {
    return {
      about: {},
      title: "",
      description: "",
    };
  },
  components: {
    Info,
    Infotwo,
  },
  methods: {
    ...mapActions(["fetchAbout","changemenuf"]),
    control(abouts) {
      for (let i = 0; i < abouts.data.length; i++) {
        const element = abouts.data[i];
        if (element.key == "title") {
          this.title = element.value;
        } else if (element.key == "description") {
          this.description = element.value;
        }
      }
      console.log(this.description)
    },
  },
  async mounted() {
    this.about = await this.fetchAbout();
    this.control(this.about);
  
    this.changemenuf()
  },
};
</script>

  <template>
  <div class="wrapper1">
    <div class="wrapper2">
     
     
      <div class="content">
        <h1 class="title" v-if="about.data">{{ title }}</h1>
        <!-- <h1 class="title" >ADASTRA HAKKINDA</h1> -->
       <p class="description">{{ description }} </p>
        <!-- <p class="description">Geleceğin İnşası İçin Çalışıyoruz</p> -->
      </div>
    </div>

    <div v-for="(item, index) in about.data" :key="item.id" >
      <Info v-if="index %2 ==0 && index != 0 && index != 1" :item="item" />
      <Infotwo v-if="index %2 !=0 &&index != 1" :item="item"/>
    </div>
    <!-- <Info  />
      <Infotwo /> -->
  </div>
</template>

<style scoped>
body {
  background-color: #000;
}

.wrapper2 {
  width: 100%;
  height: 100vh;
  display: flex;
 background-image: url(../assets/hakkımızda.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.photo {
  position: absolute;
}
.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  margin-left: 8%;
}
.title {
  font-size: 65px;
  font-weight: bold;
}
.description {
  font-size: 40px;
  text-align: center;
  font-weight: normal;
}
@media screen and (max-width: 1030px) {
  .title {
    font-size: 48px;
  }
  .description {
    font-size: 30px;
    text-align: center;
    font-weight: normal;
  }
}
@media screen and (max-width: 550px) {
  .title {
    font-size: 30px;
  }
  .description {
    font-size: 20px;
    text-align: center;
    font-weight: normal;
  }
  .content {
    margin-left: 2%;
  }
}
@media screen and (max-width: 450px) {
.wrapper2 {
  
 background-image: url(../assets/hakkimizda2.png);
 
}
}
</style>