
<template>
  <div id="app">
    <Nav />
    <router-view></router-view>
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import LoginRegister from "@/views/LoginRegister.vue";
import { mapState, mapActions } from "vuex";
import router from "./router";
export default {
  components: {
    LoginRegister,
    Nav,
  },
  computed: {
    ...mapState(["shopCart"]),
  },
  methods: {
    ...mapActions(["initAuth", "setShopCart"]),
  },
  mounted() {
    let sCart = JSON.parse(localStorage.getItem("cart"));
    if (sCart != null) {
      this.setShopCart(sCart);
    }else{
       this.setShopCart([]);
    }
  },
  async created() {
    await this.initAuth();
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "IBM Plex Sans", sans-serif;
}
body {
  background: linear-gradient(
    180.47deg,
    #222132 36.95%,
    #120f1b 68.6%,
    #000000 99.59%
  );
}
a {
  text-decoration: none !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c2e59;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #021526;
}
</style>
