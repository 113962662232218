import axios from "axios"
const axiosInstance = () => {
  let accessToken =  $cookies.get("tokenAdastra")
    const axiosInstance = axios.create({
      baseURL: "https://adastrarobotveuzayteknolojileri.com",//https://localhost:5001/"
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`, //the token is a variable which holds the token
      },
    });
    return axiosInstance;
};
export default axiosInstance;